import '../../App.css';
import React,{useEffect, useRef, useState, useCallback} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import toast,{ Toaster } from 'react-hot-toast';
import Webcam from 'react-webcam';
import { Modal } from 'reactstrap';
import { format } from "date-fns";

import { getToken, getUser } from '../../Helpers/auth-helper';
import { getData, postData, postImage } from '../../Helpers/api-helper';
import Loader from '../../Components/Loader';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import addImagePic from '../../Assets/Images/add_image_gallery.png';
import addImagePCP from '../../Assets/Images/add-pcp.png';
import deletePCP from '../../Assets/Images/delete-pcp.png';
import forwardIcon from '../../Assets/Images/forward.png';
import xIcon from '../../Assets/Images/x-close.png';
import backIcon from '../../Assets/Images/back-img.png';
import editIcon from '../../Assets/Images/pencil.png';
import defaultPic from '../../Assets/Images/default_pic.png';
import Select from 'react-select'

function DetailPemajangan(){
    
  const fileInputRef=useRef();
  const webcamRef = useRef(null);
    const data_user = getUser()
	const navigate = useNavigate();
    const {state} = useLocation();
    const {item,transaction_type,outlet_transaction_id,data_outlet} = state;

    const [order_number,set_order_number] = useState(item?.order_number ?? 1);
    const [show_type,set_show_type] = useState(0);
    const [is_update,set_is_update] = useState(false);
    const [is_planogram,set_is_planogram] = useState(0);
    const [quantity,setQuantity] = useState(0);
    const [latest_order_number,set_latest_order_number] = useState(item?.order_number ?? 1);
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_BACKEND_URL/1000);
    const [notes, setNotes] = useState(item?.notes ?? null);
    const [modalVisible, setModalVisible] = useState(false);
    const [imageURI, setImageURI] = useState(null);
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [modalCamera, setModalCamera] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [cameraPosition, switchCamera] = useState(true);
    const [show_data, set_show_data] = useState(item?.order_number ?? false);
    const [data_list,set_data_list] = useState([]);

    const [products,set_products] = useState();

    const [selectedProductArray, setSelectedProductArray] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const videoConstraints = {
      width: 640,
      height: 480,
        facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };
  
    const refreshView = () => {
    //   global.activeScreen = 'PriceComparisonScreen';
    //   console.log('Opening PriceComparisonScreen');
    //   navigation.setOptions({title: 'Detail Harga'});
        getProduct().then (items => set_products(items))
    }
  
    useEffect(() => {
        refreshView();
        getProductDisplay()
    }, []);

    const getProductDisplay=async()=>{
      setLoading(true)
      set_data_list([])
      var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      let dataOptions = {product_id: item?.product_id, 
        reference_id: data_outlet?.outlet_visit_id === undefined ? 
        item?.outlet_transaction_id : data_outlet?.outlet_visit_id,
        user_id: user_id,
        reference_type: data_outlet?.outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', outlet_id: data_outlet?.outlet_id};

      // setTimeOut(global.timeOut/1000)
      // setLoading(true)
      const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_product_display', dataOptions)
      setLoading(false)

      if (response) {
          if (response?.data.length > 0) {
              set_data_list(response.data)
              set_show_data(true)
              console.log('raw order',Math.max.apply(Math, response.data.map(function(o) { return o.order_number; })))
              set_latest_order_number(Math.max.apply(Math, response.data.map(function(o) { return o.order_number; })))
          } else {
              // var resultMessage = response?.data?.message?.toString();
              // console.log('resultMessage', resultMessage);
              // toast.error(resultMessage);
          }
      }
    }
  
    const handleSubmitButton = async () => {
      const imageResponse = await sendImage()
        var image = imageResponse
        if (imageResponse === false) {
          image = ''
        }
        console.log(imageResponse,"imageResponse")
        const access_token = getToken()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
      let dataOptions = {access_token: access_token, product_id: selectedProduct, 
        reference_id: data_outlet?.outlet_visit_id === undefined ? 
        outlet_transaction_id : data_outlet?.outlet_visit_id, 
        user_id: user_id, transaction_type:'PDSPY',
        reference_type: data_outlet?.outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', outlet_id: data_outlet?.outlet_id, 
        image:image, notes: notes,  order_number:order_number,
        is_planogram:is_planogram ? "1" : "0",quantity:quantity,show_type:show_type,
        created_by: user_id, entry_date:item?.entry_date};
        setLoading(true)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_product_display', dataOptions)
  
        if (response) {
          setLoading(false)
          if (response?.data?.success == 1) {
            toast.success('Data sudah tersimpan');
            // navigate(-1)
            getProductDisplay()
            set_show_data(true)
            resetForm()
            set_is_update(false)
          } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
          }
        }else{
          setLoading(false)
        }
    };

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      console.log(imageName)
      setImageURI(imageSrc)
      
      setImageName(format(new Date(), "yyyyMMdd")+imageName)
      setModalCamera(false)
      setModalVisible(false)
    }, [webcamRef, setImageURI]);

    const sendImage = async () => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
        if (imageURI != '' && imageURI != null) {
            let response_img = await fetch(imageURI);
            let data = await response_img.blob();
            let metadata = {
                type: 'image/jpeg'
            };
            let new_file = new File([data], imageName, metadata);
            const response = await postImage(new_file,imageName)
            setLoading(false)
        
            if (response) {
                if (response?.data?.success == 1) {
                    var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                    imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                    + imageName.substring(6,8) + '/' + imageName;
                    return new_pic
                } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', response);
                    if (resultMessage !== "File sudah ada di server") {
                        // toast.error(resultMessage);
                        return imageURI
                    } else {
                        var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
                        imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
                        + imageName.substring(6,8) + '/' + imageName;
                        return new_pic
                    }
                }
            } else {
                return false
            }
        }else{
            return ''
        }
    }

    const createNewData=()=>{
        resetForm()
        setImageURI(null)
        setNotes(null)
        set_is_update(false)
        set_show_data(false)
        set_order_number(latest_order_number+1)
    }

    const DeletePCP=async(data)=>{
      setLoading(true)
      var user_id = data.user_id ? data.user_id : data.id
      let dataOptions = {product_id: data.product_id, 
        reference_id: data_outlet?.outlet_visit_id === undefined ? 
        data.outlet_transaction_id : data_outlet?.outlet_visit_id, 
        user_id: user_id,
        reference_type: data_outlet?.outlet_visit_id === undefined ? 'NOTVIS' : 'OUTVIS', outlet_id: data.outlet_id, order_number:data.order_number, entry_date:data.entry_date};

      // setTimeOut(global.timeOut/1000)
      // setLoading(true)
      const response = await getData(process.env.REACT_APP_BACKEND_URL+'/delete_product_display', dataOptions)
      setLoading(false)

      if (response) {
          if (response?.data) {
            getProductDisplay()
            toast.success('Hapus data berhasil')
          } else {
              var resultMessage = response?.data?.message?.toString();
              console.log('resultMessage', resultMessage);
              toast.error(resultMessage);
          }
      }
    }

    const getProduct = async () => {
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        var access_token = getToken()
        let dataOptions = {access_token: access_token, user_id: user_id, 
        transaction_type: 'STOCK', 
        reference_id: data_outlet?.outlet_visit_id === undefined || data_outlet?.outlet_visit_id == null ? 
        outlet_transaction_id : data_outlet?.outlet_visit_id,
        reference_type: 'NOTVIS'};
          
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/product_transaction_by_user', dataOptions)
    
        if (response) {
            setLoading(false)
            if (response?.data?.success == 1) {
                var jsonData = response.data.data;
                console.log(jsonData);
                
                const tmpProducts = [];
                for (let i = 0; i < jsonData.length; i++) {
                    tmpProducts.push({
                    label: jsonData[i].product,
                    value: jsonData[i].product_id,
                    });
                }
                return tmpProducts;
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage);
            }
        }else{
            setLoading(false)
        }
    }

    const onSelectedProductChange = (selectedItems) => {
      setSelectedProduct(selectedItems.value.value);
      setSelectedProductArray(selectedItems.value)
      console.log(selectedItems.value)
    };

    const kembali=()=>{
        resetForm()
        set_is_update(false)
        set_show_data(true)
    }

    const resetForm=()=>{
        set_is_planogram(0)
        set_show_type(0)
        setQuantity(0)
        setSelectedProduct("")
        setNotes("")
        setSelectedProductArray([])
    }

    const shareNow = async () => {
      setLoading(true)
      let imageResponse = await fetch(imageURI);
      let imageBuffer = await imageResponse.arrayBuffer();
      let fileArray = [new File([imageBuffer], "share_img.jpg", {
        type: "image/jpeg",
        lastModified: Date.now()
      })];
      
      console.log(fileArray)
      if(navigator && navigator.canShare && navigator.canShare({files: fileArray})){
        navigator.share({
          files: fileArray,
          title: '[New ARM Mobile]',
          text: '[New ARM Mobile]'
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch("SPeeding car going 90 in the rain",console.error);
          setLoading(false)
      }else{
        setLoading(false)
      }
    }

    if(show_data){
      return(
        <div className='page-background'>
            <Modal isOpen={modalView} onClosed={() => setModalView(false)} centered toggle={() => setModalView(false)}>
            <div style={{display:"flex",alignItems:"end",flexWrap:"nowrap",flexDirection:"row",justifyContent:"flex-end",padding:"10px"}}>
            {/* <div style={{position:'absolute',right:"20px"}}> */}
                <img onClick={() => {
                    set_show_data(false)
                    setModalView(false)
                    }} src={editIcon} width={45} height={45} style={{marginRight:"10px"}}/>
                <img onClick={() => shareNow()} src={forwardIcon} width={45} height={45} style={{marginRight:"10px"}}/>
                <img onClick={() => setModalView(false)} src={xIcon} width={45} height={45}/>
            </div>
            <div style={{padding:15,marginLeft:'auto',marginRight:'auto'}}>
                    <div style={{maxHeight:500,minHeight:200,maxWidth:"100%"}}>
                        <img src={imageURI ? imageURI : defaultPic} style={{margin:'auto',display:'block',width:"100%",height:'auto',maxHeight:500}}/>
                    </div>
                    <div>
                        <p style={{fontSize:21,fontWeight:600,marginTop:2,marginBottom:2}}>Kategori </p>
                        <label style={{fontSize:16,width:"50%",whiteSpace:'normal'}}>{show_type == "1" ? "Sesudah" : "Sebelum"}</label>
                    </div>
                    <div>
                        <p style={{fontSize:21,fontWeight:600,marginTop:2,marginBottom:2}}>Produk </p>
                        <label style={{fontSize:16,width:"50%",whiteSpace:'normal'}}>{selectedProductArray.label}</label>
                    </div>
                    <div>
                        <p style={{fontSize:21,fontWeight:600,marginTop:2,marginBottom:2}}>Space Share </p>
                        <div style={{display:"flex",justifyContent:"space-evenly"}}>
                            <label style={{fontSize:16,width:"50%",whiteSpace:'normal'}}>{selectedProductArray.label}</label>
                            <label style={{fontSize:16,width:"50%",whiteSpace:'normal'}}>{"Quantity : "+quantity}</label>
                        </div>
                    </div>
                    {is_planogram && <p style={{fontSize:21,fontWeight:600,marginTop:2,marginBottom:2}}>Planogram terpasang </p> }
                    <div>
                        <p style={{fontSize:21,fontWeight:600,marginTop:2,marginBottom:2}}>Catatan </p>
                        <label style={{fontSize:16,width:"50%",whiteSpace:'normal'}}>{notes}</label>
                    </div>
                </div>
            </Modal>
          <Loader loading={loading} timeOut={60}/>
          <div className='detail-page'>
          <div className='wrap-scroll' style={{height:'90vh'}}>
              <div className='scroll-content'>
            <div className='list-images'>
              {
                data_list && data_list.map((data)=>
                  <div className='item-docs'>
                      <img onClick={() => DeletePCP(data)} src={deletePCP} width={50} height={50} className='delete-item-pcp'/>
                      <img onClick={() => {
                      setModalView(true)
                      set_order_number(data.order_number)
                      setNotes(data.notes)
                      setImageURI(data.image)
                      setQuantity(data.quantity)
                      set_show_type(data.show_type.toString())
                      const findProduct = products.filter(item => item.value == data.product_id)
                      setSelectedProductArray(findProduct[0])
                      setSelectedProduct(findProduct[0].value)
                      set_is_planogram(data.is_planogram)
                      set_is_update(true)
                      }} src={data?.image ? data?.image : defaultPic} className='item-image' width={'100%'} height={150}/>
                      <p>{data.show_type == "1" ? "Sesudah" : "Sebelum"}</p>
                  </div>
                )
              }
              <div className='add-image'>
                  <img onClick={() => createNewData()} src={addImagePCP} className='item-image' width={'100%'} height={150}/>
              </div>
            </div>
            </div>
          </div>
          </div>
        </div>
      )
    }else{
      return(
          <div className='page-background'>
          <Loader loading={loading} timeOut={60}/>
              <div className='detail-page'>
                {data_list.length > 0 && <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",marginLeft:"20px"}} onClick={() => kembali()}>
                    <img src={backIcon} width={45} height={45} style={{marginRight:"5px"}}/>
                    <p style={{fontWeight:600,fontSize:"21px",marginTop:"auto",marginBottom:"auto"}}>Kembali</p>
                </div>}
                <div className='wrap-scroll' style={{height:'83vh'}}>
                    <div className='scroll-content'>
                    &nbsp;
                        <div className='add-image'>
                            <img onClick={() => {
                                setModalVisible(true)
                                // startCamera()
                                }} src={imageURI ? imageURI : addImagePic} className='add-image-button' width={'100%'} height={150}/>
                        </div>
                        &nbsp;
                        <div className='form-tambah-2 d-flex'>
                            <button className={ show_type === '0' ? "btn-red-1" : "btn-white-1"} onClick={() => set_show_type('0')}>Sebelum</button>
                            <button className={ show_type === '1' ? "btn-red-1" : "btn-white-1"} onClick={() => set_show_type('1')}>Sesudah</button>
                        </div>
                        &nbsp;
                        <div className='form-tambah'>
                            <Select
                                isDisabled={is_update}
                                className="form-select-2"
                                classNamePrefix="Pilih Produk"
                                placeholder={'Pilih Produk'}
                                isSearchable={true}
                                name="product"
                                value={selectedProductArray}
                                options={products && products}
                                onChange={(value,e) => onSelectedProductChange({value})}
                            />
                        </div>
                        { selectedProductArray?.label &&
                            <div style={{marginLeft:'30px',marginRight:'30px',marginTop:"5px"}}>
                                <p style={{textAlign:'left'}}>Isi Jumlah Facing</p>
                                    <div style={{borderRadius:"6px",backgroundColor:"white",display:'flex',justifyContent:"space-evenly",padding:"10px 0px 10px 0px"}}>
                                        <p className='m-auto'>{selectedProductArray.label}</p>
                                        <div className='form-tambah'>
                                            <input className='form-input-qty' type='number' placeholder='' onChange={(e) => setQuantity(e.target.value)} value={quantity}/>
                                        </div>
                                    </div>
                            </div>
                        }
                        {show_type == "1" && 
                        <div style={{display:'flex',justifyContent:"space-evenly",marginTop:"8px",marginBottom:"8px"}}>
                            <p style={{marginTop:'auto',marginBottom:'auto'}}>Apakah planogram sudah terimplementasi</p>
                            <input type='checkbox' name="is_planogram" value={is_planogram} defaultChecked={is_planogram} onChange={(e) =>set_is_planogram(e.target.checked)}/>
                        </div>}
                      <textarea className='form-textarea-1' placeholder='Catatan' value={notes} onChange={(e) => setNotes(e.target.value)}/>
                  </div>
                  <button className='outlet-checkout-button' onClick={() => handleSubmitButton()}>SIMPAN</button>
                  </div>
              </div>
              <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                      <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                      <input
                          ref={fileInputRef}
                          type="file"
                          name="imageURI"
                          onChange={(event) => {
                              console.log(event.target.files[0])
                              setImageURI(URL.createObjectURL(event.target.files[0]))
                              setImageFile(event.target.files[0])
                              setImageName(format(new Date(), "yyyyMMdd")+event.target.files[0].name)
                              setModalVisible(false)
                          }}
                          style={{display:'none'}}
                      />
                      <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                      <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                          <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                              minScreenshotWidth={640}
                              minScreenshotHeight={'100%'}
                              screenshotQuality={1}
                              label="wow"
                              style={{
                              position: "absolute",
                              zindex: 888,
                              left:0,
                              right:0,
                              height: "90vh",
                              width: "100%",
                              objectFit: "cover",
                              }}
                          />
                          <div className='group-camera-buttons'>
                              <div className='camera-button' onClick={() => setModalCamera(false)}>
                                  <p style={{color:"black"}}>BATAL</p>
                              </div>
                              <div className='camera-button'>
                                  <img onClick={takeAPic} src={capturePic} width={70} height={70}/>
                              </div>
                              <div className='camera-button'>
                                  <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                              </div>
                          </div>
                      </Modal>
              </Modal>
          </div>
      )
    }
}

export default DetailPemajangan