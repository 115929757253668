import React,{useEffect, useState,useRef,useCallback} from "react";
import Webcam from 'react-webcam';
import { format, differenceInDays, } from "date-fns";

import logoAdvan from '../../Images/new_logo.png';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import imageAdvan from '../../Assets/Images/logo_advan.png';
import { Modal,ModalBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken, getUser, setOutletId,removeSession } from "../../Helpers/auth-helper";
import toast,{ Toaster } from "react-hot-toast";
import Loader from "../../Components/Loader";
import { postData, postImage } from "../../Helpers/api-helper";

const KameraCheck=()=>{

    const navigate = useNavigate()
    const {state} = useLocation()
    const webcamRef = useRef(null);
    const [cameraPosition, switchCamera] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const {data_outlet,gpsLatitude,gpsLongitude} = state
    
    const [imageURI, setImageURI] = useState('');
    const [imageName, setImageName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imageDebug, setImageDebug] = useState('');

    useEffect(() => {
      // checkCamera()
    },[])

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };

    const takeAPic = useCallback(() => {
      // toast.success('haiz')
      //testing
      // setModalVisible(true)

      setLoading(true)
      const imageSrc = webcamRef.current.getScreenshot();

      var img=new Image();
      img.crossOrigin='anonymous';
      img.src=imageSrc;
      img.onload = function(){
        watermarkingImage(img)
      }
      
    }, [webcamRef]);

    const watermarkingImage=(img)=>{
      var canvas = document.createElement('canvas');
      canvas.id = 'canvas-id';
      var imgWatermark=new Image();
      imgWatermark.crossOrigin='anonymous';
      imgWatermark.src=logoAdvan;
      imgWatermark.width=170;
      imgWatermark.height=35;

      imgWatermark.onload = function(){
        console.log('start watermarking')
        var data_uri = createWatermark(canvas,img,'image',imgWatermark,'top-right',1)
        console.log("data_uri",data_uri)
        // if(data_uri !== 'data:,'){
          setModalVisible(true)
          setLoading(false)
          var imageName = new Date().getTime()+".png";
          setImageName(imageName)
          setImageURI(data_uri)
        // }else if(data_uri == 'data:,'){
        //   setLoading(false)
        //   toast.error('Terjadi kesalahaan saat pengambilan gambar!')
        // }
      }
    }

    const createWatermark = (canvas, baseImage, type, watermarkImage, position, alpha) => {
      console.log(canvas, baseImage, type, watermarkImage, position, alpha)
      const ctx = canvas.getContext('2d');
      canvas.width = baseImage.width;
      canvas.height = baseImage.height;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(baseImage, 0, 0, baseImage.width, baseImage.height);
      ctx.globalAlpha = alpha;
      const [x, y] = getCoordinates(
        position,
        watermarkImage.width,
        watermarkImage.height,
        baseImage.width,
        baseImage.height
      );
      ctx.drawImage(
        watermarkImage,
        x,
        y,
        watermarkImage.width,
        watermarkImage.height
      );
      const [x1, y1] = getCoordinates(
        'bottom-left',
        watermarkImage.width,
        watermarkImage.height,
        baseImage.width,
        baseImage.height-110
      );
      const [x2, y2] = getCoordinates(
        'bottom-left',
        watermarkImage.width,
        watermarkImage.height,
        baseImage.width,
        baseImage.height-85
      );
      const [x3, y3] = getCoordinates(
        'bottom-left',
        watermarkImage.width,
        watermarkImage.height,
        baseImage.width,
        baseImage.height-60
      );
      const [x4, y4] = getCoordinates(
        'bottom-left',
        watermarkImage.width,
        watermarkImage.height,
        baseImage.width,
        baseImage.height-35
      );
      var data_user = getUser()
      var user_name = data_user.name
      ctx.font="22px verdana";
      ctx.fillStyle='white'
      ctx.shadowColor="black";
      ctx.shadowBlur=7;
      ctx.fillText(data_outlet?.outlet.toUpperCase(),x1, y1);
      ctx.fillText(user_name,x2, y2);
      ctx.fillText(format(new Date(), "dd MMMM yyyy HH:mm:ss"),x3, y3);
      printAtWordWrap(ctx,data_outlet?.address,x4,y4,25,baseImage.width)
      document.body.appendChild(canvas);
      var Pic = canvas.toDataURL("image/jpeg");
      return Pic;
    };

    const printAtWordWrap=( context , text, x, y, lineHeight, fitWidth)=>
    {
        fitWidth = fitWidth || 0;
        
        if (fitWidth <= 0)
        {
            context.fillText( text, x, y );
            return;
        }
        var words = text.split(' ');
        var currentLine = 0;
        var idx = 1;
        while (words.length > 0 && idx <= words.length)
        {
            var str = words.slice(0,idx).join(' ');
            var w = context.measureText(str).width;
            if ( w > fitWidth )
            {
                if (idx==1)
                {
                    idx=2;
                }
                context.fillText( words.slice(0,idx-1).join(' '), x, y + (lineHeight*currentLine) );
                currentLine++;
                words = words.splice(idx-1);
                idx = 1;
            }
            else
            {idx++;}
        }
      if  (idx > 0)
          context.fillText( words.join(' '), x, y + (lineHeight*currentLine) );
      }

    const getCoordinates = (
      position,
      imageWidth,
      imageHeight,
      canvasWidth,
      canvasHeight
    ) => {
      const xCenter = canvasWidth / 2 - imageWidth / 2;
      const yCenter = canvasHeight / 2 - imageHeight / 2;
      switch (position) {
        case "top-left":
          return [0, 0];
        case "top-center":
          return [xCenter, 0];
        case "top-right":
          return [canvasWidth - (imageWidth+25), 30];
        case "bottom-left":
          return [15, canvasHeight - imageHeight];
        case "bottom-center":
          return [xCenter, canvasHeight - imageHeight];
        case "bottom-right":
          return [canvasWidth - imageWidth, canvasHeight - imageHeight];
        case "center":
          return [xCenter, yCenter];
        default:
          return [0, 0];
      }
    };

    const handleBatalButton=()=>{
        setModalVisible(false)
        navigate('/')
    }

    const sendImage = async () => {
      console.log("imageFile",imageFile)
      // var new_file = URL.createObjectURL(imageURI)
      let response_img = await fetch(imageURI);
      let data = await response_img.blob();
      let metadata = {
        type: 'image/jpeg'
      };
      let new_file = new File([data], imageName, metadata);
      const response = await postImage(new_file,imageName)
    //   setLoading(false)
  
      if (response) {
        if (response?.data?.success == 1) {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            // setPicture(new_pic)
            return new_pic
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', response);
          if (resultMessage !== "File sudah ada di server") {
            toast.error(resultMessage);
            return false
          } else {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            // setPicture(new_pic)
            return new_pic
          }
        }
      } else {
        return false
      }
    }

    const handleOkButton=async()=>{
        //testing
        // const imageResponse = ''
        setLoading(true)
        const imageResponse = await sendImage()

        if (imageResponse === false) {
          setLoading(false)
          return false;
        }
        var access_token = getToken()
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        let dataOptions = {access_token: access_token, outlet_name: data_outlet?.outlet, outlet_id: data_outlet?.outlet_id, status: 'CEKIN', 
          user_id: user_id, image: imageResponse, outlet_visit_id: data_outlet?.outlet_visit_id, //can use this if bugs 67 wont work
          latitude: gpsLatitude, longitude: gpsLongitude, 
          distance: data_outlet?.distance};
    
        // setTimeOut(global.timeOut/1000)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/add_outlet_visit', dataOptions)
        setLoading(false)
        if (response) {
          if (response?.data?.success == 1) {
            var oldcanv = document.getElementById('canvas-id');
            // comment to testing
            document.body.removeChild(oldcanv)
            setModalVisible(false)
            let stream = webcamRef.current.video.srcObject;
            if(stream !== null){
              const tracks = stream.getTracks();
              
              tracks.forEach(track => track.stop());
              webcamRef.current.video.srcObject = null;
            }
            setOutletId(response.data.outlet_visit_id)
            global.hrStatus = "CEKOUT";
            global.hrStatusDesc = "Check Out";
            data_outlet.outlet_visit_id = response.data.outlet_visit_id
            data_outlet.check_in_image = imageResponse
            navigate('/outlet', { state: { data_outlet: data_outlet } })
            global.check_in_image = imageResponse;
          } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
          }
        }
    }

    return(
        <div style={{height:"100%",backgroundColor:"black"}}>
        <Loader loading={loading} timeOut={60}/>
        <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} fullscreen centered>
            <ModalBody>
            {imageDebug && <p>{imageDebug}</p>}
                <img style={{objectFit:'contain'}} src={imageURI} width={"100%"} height={"100%"}/>
            </ModalBody>
            <div className="btn-group-modal-footer">
                <button className="btn-modal-footer" onClick={() => handleBatalButton()}>BATAL</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button className="btn-modal-footer" onClick={() => handleOkButton()}>OKE</button>
            </div>
        </Modal>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                minScreenshotWidth={720}
                minScreenshotHeight={1280}
                screenshotQuality={0.7}
                label="wow"
                style={{
                  position: "absolute",
                  zindex: 888,
                  left:0,
                  right:0,
                  height: "90vh",
                  width: "100%",
                  objectFit: "cover",
                }}
            />
            <div className='group-camera-buttons'>
                <div className='camera-button'>
                    <p style={{color:"black"}} onClick={() => navigate(-1)}>BATAL</p>
                </div>
                <div className='camera-button'>
                    <img onClick={() => takeAPic()} src={capturePic} width={70} height={70}/>
                </div>
                <div className='camera-button'>
                    <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                </div>
            </div>
        </div>
    )
}

export default KameraCheck