import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { postData } from '../Helpers/api-helper';

const firebaseConfig = {
    apiKey: "AIzaSyDrkXue00_MIYWW9RqcLZySlFG7MhEzW9s",
    authDomain: "arm-pwa-47d8b.firebaseapp.com",
    projectId: "arm-pwa-47d8b",
    storageBucket: "arm-pwa-47d8b.appspot.com",
    messagingSenderId: "70422563590",
    appId: "1:70422563590:web:8772e62d527e2a1c4e6c87",
    measurementId: "G-3M1ELDXSQK"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async (user_id) => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BC7XOL3ZuFhJTraS7t1wi0S7Fsa7w346IbLseyAdmUbg8dzSP7SroKiEh_xLNalXpRQOTBmqYCr2E_WoAjzxu6s` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                var params = {user_id:user_id,token:currentToken}
                if(localStorage.getItem('fcmToken') && user_id && currentToken !== localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);
                    postData(process.env.REACT_APP_BACKEND_URL+"/user_create_token_fcm",params);
                    // toast('update current token for client: ');
                }else if(!localStorage.getItem('fcmToken') && user_id){
                    localStorage.setItem('fcmToken', currentToken);
                    postData(process.env.REACT_APP_BACKEND_URL+"/user_create_token_fcm",params);
                    // toast('create current token for client: ');
                }
            } else {
                // toast('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            // toast('An error occurred while retrieving token. '+err);
        });
};

export const onMessageListener = () =>
new Promise((resolve) => {
    onMessage(messaging, (payload) => {
    resolve(payload);
    });
});