import '../../App.css';
import React,{useRef, useState,useCallback, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Modal } from 'reactstrap';
import Webcam from 'react-webcam';
import toast from 'react-hot-toast'
import Select from 'react-select'

import addImagePic from '../../Assets/Images/add_image_gallery.png';
import GoogleMaps from '../../Components/GoogleMaps.tsx';
import capturePic from '../../Assets/Images/capture.png';
import flipPic from '../../Assets/Images/flip.png';
import { getToken, getUser, removeSession } from '../../Helpers/auth-helper.js';
import { getData, postData, postImage } from '../../Helpers/api-helper.js';
import MapAddOutlet from '../../Components/MapAddOutlet.tsx';
import Loader from '../../Components/Loader.js'

function TambahOutlet(){

    const sha256 = require('js-sha256');
    const fileInputRef=useRef();
    const webcamRef = useRef(null);
	const navigate = useNavigate();
	const {state} = useLocation();
    const {data_outlet,notCalibrate,firstLatitude,firstLongitude,address_outlet} = state;
    const [loading, setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(process.env.REACT_APP_TIMEOUT_IMG/1000);
    const [gpsLongitude, setGpsLongitude] = useState();
    const [gpsLatitude, setGpsLatitude] = useState();
    const [outletName, setOutletName] = useState(data_outlet?.outlet);
    const [categories, setCategories] = useState();
    const [subCategories, setsubCategories] = useState();
    const [provinces, setProvinces] = useState();
    const [regencies, setRegencies] = useState();
    const [districts, setDistricts] = useState();
    const [villages, setVillages] = useState();
    const [postalCode, setPostalCode] = useState();
    const [phone_number, setPhoneNumber] = useState('');
    const [nama_pemilik, setNamaPemilik] = useState('');
  
    const [selectedCategoryArray, setSelectedCategoryArray] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategoryArray, setSelectedSubCategoryArray] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedProvinceArray, setSelectedProvinceArray] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedRegencyArray, setSelectedRegencyArray] = useState([]);
    const [selectedRegency, setSelectedRegency] = useState('');
    const [selectedDistrictArray, setSelectedDistrictArray] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedVillageArray, setSelectedVillageArray] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState('');
    const [modalCamera, setModalCamera] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isPermitted, setIsPermitted] = useState(true);
    const [imageURI, setImageURI] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imageName, setImageName] = useState('');
    const [cameraPosition, switchCamera] = useState(true);
    const [address, setAddress] = useState();
    const [picture, setPicture] = useState('');

    const videoConstraints = {
        width: 640,
        height: 480,
          facingMode: { exact: cameraPosition ? 'environment' : 'user' },
    };

    const getAddressFromCoordinates = async (latitude, longitude) => {
      try {
        var system_identity_key = localStorage.getItem('system_identity_key')
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        const address_id = String(system_identity_key).trim() + String(user_id).trim() + String(data_user.user_name).trim() + 
        String(data_user.name).trim() + String(latitude) + String(longitude) + format(new Date(), "yyyyMMdd");
        var address_hash = "";
  
        // await sha256(address_id).then(hash => {
        //   address_hash = hash;
        // })
        address_hash = sha256(address_id)
  
        console.log("address_id", address_id)
        console.log("address_hash", address_hash)
  
        if (address_hash !== "") {
          let paramOptions = {latitude: latitude, longitude: longitude, user_id: user_id, address_id: address_hash};
          const response = await getData(process.env.REACT_APP_BACKEND_URL+'/get_address_from_coordinates', paramOptions)
          if (response) {
            if (response?.data?.success == 1) {
              console.log("Address from Maps", response?.data?.address);
              setAddress(response?.data?.address)
              return response?.data?.address;
            } else {
              return response?.data?.message;
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    const setNewLocation = async(latitude,longitude) => {
      await getAddressFromCoordinates(latitude, longitude)
    }

    useEffect(() => {
        setNewLocation(gpsLatitude,gpsLongitude)
    },[gpsLatitude,gpsLongitude])

    const openModalImage=()=>{
        setModalVisible(true)
        // startCamera()
    }

    const takeAPic = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      var imageName = new Date().getTime()+".jpg";
      console.log(imageName)
      setImageURI(imageSrc)
      
      setImageName(format(new Date(), "yyyyMMdd")+imageName)
      setModalCamera(false)
      setModalVisible(false)
    }, [webcamRef, setImageURI]);

    useEffect(() => {
        // console.log("state",state.state)
        refreshView()
        console.log(firstLatitude,firstLongitude)
    },[])

    // const startCamera=async()=>{

    //   checkCamera().then(async(res) => {
    //     console.log(res.state,"camera_permit")
    //     if(res.state == 'denied'){
    //       removeSession()
    //       navigate('/login')
    //       window.location.reload();
    //     }else if(res.state == 'prompt'){
    //       const permissions = navigator.mediaDevices.getUserMedia({video: true})
    //       permissions.then((stream) => {
    //           console.log(stream)
    //           return true
    //       })
    //       .catch((err) => {
    //           removeSession()
    //           navigate('/login')
    //           window.location.reload();
    //       });
    //     }
    //   }
    //   ).catch((e) => 
    //     console.log(e,"camera_permit")
    //   )
    // }

    const refreshView = () => {
      global.activeScreen = 'AddOutletScreen';
      console.log('Opening AddOutlet Screen');
      if (data_outlet?.not_calibrate === false) {
        // document.title = 'Kalibrasi Outlet';
      }
      global.outletName = '';
      global.outletDistance = '';
      global.outletDistanceDesc = '';
      global.outletPhoneNumber = '';
      global.ownerName = '';
      global.outletPicture = '';
      global.outletID = '';
      global.imageURI = '';
      global.imageName = '';
      global.picture = '';
      global.storeCategoryCode = '';
      global.storeCategoryCodeLength = 1;
      setGpsLatitude(firstLatitude)
      setGpsLongitude(firstLongitude)
      getStoreCategory().then (items => setCategories(items));
      global.regionCode = '';
      global.regionCodeLength = 2;
      getRegion().then (items => setProvinces(items));
    }
  
    const getStoreCategory = async () => {
        var access_token = getToken()
        let dataOptions = {access_token: access_token, id: global.storeCategoryCode, length: global.storeCategoryCodeLength};
        
        // setTimeOut(global.timeOut/1000)
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/outlet_category_list', dataOptions)
        setLoading(false)
    
        if (response) {
            if (response?.data?.success == 1) {
            var jsonData = response.data.data;
            console.log(jsonData);
            
            const tmpStoreCategory = [];
            for (let i = 0; i < jsonData.length; i++) {
                tmpStoreCategory.push( {
                label: jsonData[i].description,
                value: jsonData[i].code,
                });
            }
            return tmpStoreCategory;
            } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
            //   toast.error(resultMessage);
            }
        }
    }

    const getRegion = async () => {
        var access_token = getToken()
        let dataOptions = {access_token: access_token, id: global.regionCode, length: global.regionCodeLength};
        
        // setTimeOut(global.timeOut/1000)
        // setLoading(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+'/region_list', dataOptions)
        setLoading(false)
    
        if (response) {
            if (response?.data?.success == 1) {
            var jsonData = response.data.data;
            console.log(jsonData);
            
            const tmpRegion = [];
            for (let i = 0; i < jsonData.length; i++) {
                tmpRegion.push({
                label: jsonData[i].description,
                value: jsonData[i].code,
                });
            }
            return tmpRegion;
            } else {
            var resultMessage = response?.data?.message?.toString();
            console.log('resultMessage', resultMessage);
            toast.error(resultMessage);
            // toast.error(resultMessage);
            }
        }
    }

    const sendImage = async () => {
      // setTimeOut(global.imageTimeOut/1000)
      // setLoading(true)
      let response_img = await fetch(imageURI);
      let data = await response_img.blob();
      let metadata = {
        type: 'image/jpeg'
      };
      let new_file = new File([data], imageName, metadata);
      const response = await postImage(new_file,imageName)
  
      if (response) {
        setLoading(false)
        if (response?.data?.success == 1) {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            setPicture(new_pic)
            return new_pic
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', response);
          if (resultMessage !== "File sudah ada di server") {
            // toast.error(resultMessage);
            return false
          } else {
            var new_pic = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            imageName.substring(0,4) + '/' + imageName.substring(4,6) + '/' 
              + imageName.substring(6,8) + '/' + imageName;
            setPicture(new_pic)
            return new_pic
          }
        }
      } else {
        setLoading(false)
        return false
      }
    }
   
    const onSelectedCategoryChange = (selectedItems) => {
      setSelectedCategory(selectedItems.value.value);
      setSelectedCategoryArray(selectedItems.value)
      global.storeCategoryCode = selectedItems.value.value.toString();
      global.storeCategoryCodeLength = 5;
      getStoreCategory().then (items => setsubCategories(items));
    };
  
    const onSelectedSubCategoryChange = (selectedItems) => {
      setSelectedSubCategory(selectedItems.value.value);
      setSelectedSubCategoryArray(selectedItems.value)
    };

    const onSelectedProvinceChange=(selectedItems)=>{
        setSelectedProvince(selectedItems.value.value);
        setSelectedProvinceArray(selectedItems.value)
        global.regionCode = selectedItems.value.value;
        global.regionCodeLength = 5;
        getRegion().then (items => setRegencies(items));
    }

    const onSelectedRegencyChange = (selectedItems) => {
        setSelectedRegency(selectedItems.value.value);
        setSelectedRegencyArray(selectedItems.value)
        global.regionCode = selectedItems.value.value;
        global.regionCodeLength = 8;
        getRegion().then (items => setDistricts(items));
    };

    const onSelectedDistrictChange = (selectedItems) => {
      setSelectedDistrict(selectedItems.value.value);
      setSelectedDistrictArray(selectedItems.value)
      global.regionCode = selectedItems.value.value;
      global.regionCodeLength = 13;
      getRegion().then (items => setVillages(items));
    };
  
    const onSelectedVillageChange = (selectedItems) => {
      setSelectedVillage(selectedItems.value.value);
      setSelectedVillageArray(selectedItems.value)
    };

    const validateForm=async()=>{
        setLoading(true)
        var access_token = getToken()
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        if (data_outlet?.not_calibrate === false) {
            let dataOptions = {access_token: access_token, id: data_outlet?.outlet_id, 
            address: address, longitude: gpsLongitude, 
            latitude: gpsLatitude, record_status: 'U', updated_by: user_id};
        
            // setTimeOut(global.timeOut/1000)
            setLoading(true)
            const response = await postData(process.env.REACT_APP_BACKEND_URL+'/update_outlet', dataOptions)
            setLoading(false)
            
            if (response) {
                if (response?.data?.success == 1) {
                    toast.success('Outlet sudah dikalibrasi');
                    navigate('/')
                } else {
                    var resultMessage = response?.data?.message?.toString();
                    console.log('resultMessage', resultMessage);
                    toast.error(resultMessage);
                }
            }
    
            return;
        }
        console.log(outletName)
        if (!outletName || outletName == '') {
            toast.error('Masukkan Nama Outlet');
            setLoading(false)
        }else if (!address || address == '') {
            toast.error('Masukkan Alamat Outlet');
            setLoading(false)
        }else if (!selectedCategory || selectedCategory == '') {
            toast.error('Masukkan Kategori');
            setLoading(false)
        }else if (!selectedProvince || selectedProvince == '') {
            toast.error('Masukkan Provinsi');
            setLoading(false)
        }else if (!selectedRegency || selectedRegency == '') {
            toast.error('Masukkan Kabupaten/Kota');
            setLoading(false)
        }else if (!selectedDistrict || selectedDistrict == '') {
            toast.error('Masukkan Kecamatan');
            setLoading(false)
        }else if (!selectedVillage || selectedVillage == '') {
            toast.error('Masukkan Desa/Kelurahan');
            setLoading(false)
        }else if (!phone_number || phone_number === '') {
            toast.error('Masukkan Nomor Telepon Outlet');
            setLoading(false)
        }else if (!nama_pemilik || nama_pemilik === '') {
            toast.error('Masukkan Nama Pemilik Outlet');
            setLoading(false)
        }else if (!gpsLongitude) {
            toast.error('Masukkan GPS Longitude');
            setLoading(false)
        }else if (!gpsLatitude) {
            toast.error('Masukkan GPS Latitude');
            setLoading(false)
        }
        else{
            if(imageURI){
                const imageResponse = await sendImage()
                console.log("imageResponse",imageResponse)
                if (imageResponse === false) {
                    // toast.error('Gagal upload gambar');
                    setLoading(false)
    
                    handleSubmitForm(access_token,user_id,'')
                }else{
                    handleSubmitForm(access_token,user_id,imageResponse)
                }
            }else{
                handleSubmitForm(access_token,user_id,'')
            }
        }
    }

    const handleSubmitForm=async(access_token,user_id,imageResponse)=>{
        let dataOptions = {access_token: access_token, name: outletName, 
            address: address, phone_number: phone_number, 
            owner_name: nama_pemilik, longitude: gpsLongitude, user_id: user_id, 
            latitude: gpsLatitude, created_by: user_id, image: imageResponse,
            category_id: selectedCategory, sub_category_id: selectedSubCategory, 
            province_id: selectedProvince, regency_id: selectedRegency, 
            district_id: selectedDistrict, village_id: selectedVillage, 
            postal_code: postalCode};
      
        // setTimeOut(global.timeOut/1000)
        setLoading(true)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_outlet', dataOptions)
        setLoading(false)
        
        if (response) {
            if (response?.data?.success == 1) {
                toast.success('Outlet baru sudah tersimpan');
                navigate('/')
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                // toast.error(resultMessage);
            }
        }
    }

    return(
        <div className='page-background'>
           
            <Loader loading={loading} timeOut={60}/>
            {notCalibrate && <div className='wrap-input-image'>
                <div className='form-input'>
                    <div className='add-image'>
                        <img onClick={() => openModalImage()} src={imageURI ? imageURI : addImagePic} className='add-image-button' width={250} height={150} style={{objectFit:'contain'}}/>
                    </div>
                </div>
            </div>}
            <div className='wrap-scroll' style={{height:notCalibrate ? '65vh' : '100vh'}}>
                <div className='scroll-content'>
                    <div className='form-tambah'>
                        <input className='form-input-3' placeholder='Nama Outlet' onChange={(e) => setOutletName(e.target.value)} value={outletName}/>
                    </div>
                    { notCalibrate && <>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Kategori"
                            placeholder={'Pilih Kategori'}
                            isSearchable={false}
                            name="categories"
                            value={selectedCategoryArray}
                            options={categories && categories}
                            onChange={(value,e) => onSelectedCategoryChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Sub Kategori"
                            placeholder={'Pilih Sub Kategori'}
                            isSearchable={false}
                            name="sub_categories"
                            value={selectedSubCategoryArray}
                            options={subCategories && subCategories}
                            onChange={(value,e) => onSelectedSubCategoryChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Provinsi"
                            placeholder={'Pilih Provinsi'}
                            isSearchable={false}
                            name="provinces"
                            value={selectedProvinceArray}
                            options={provinces && provinces}
                            onChange={(value,e) => onSelectedProvinceChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Kabupaten/Kota"
                            placeholder={'Pilih Kabupaten/Kota'}
                            isSearchable={false}
                            name="regency"
                            value={selectedRegencyArray}
                            options={regencies && regencies}
                            onChange={(value,e) => onSelectedRegencyChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Kecamatan"
                            placeholder={'Pilih Kecamatan'}
                            isSearchable={false}
                            name="disctrict"
                            value={selectedDistrictArray}
                            options={districts && districts}
                            onChange={(value,e) => onSelectedDistrictChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Desa/Kelurahan"
                            placeholder={'Pilih Desa/Kelurahan'}
                            isSearchable={false}
                            name="village"
                            value={selectedVillageArray}
                            options={villages && villages}
                            onChange={(value,e) => onSelectedVillageChange({value})}
                        />
                    </div>
                    <div className='form-tambah'>
                        <input className='form-input-3' type='number' placeholder='Kode Pos' onChange={(e) => setPostalCode(e.target.value)} value={postalCode}/>
                    </div>
                    </>}
                    <div style={{ height: '30vh', width: '100%' }}>
                        Pinpoint pada peta
                        <MapAddOutlet setGpsLatitude={setGpsLatitude} setGpsLongitude={setGpsLongitude} gpsLatitude={firstLatitude} gpsLongitude={firstLongitude}/>
                    </div>
                    <div className='form-tambah' style={{marginTop:25}}>
                        <textarea className='form-textarea-1' placeholder='Lokasi Detail' onChange={(e) => setAddress(e.target.value)} value={address}/>
                    </div>
                    <div className='form-tambah'>
                        <input className='form-input-3' placeholder='Latitude' onChange={(e) => setGpsLatitude(e.target.value)} value={gpsLatitude}/>
                    </div>
                    <div className='form-tambah'>
                        <input className='form-input-3' placeholder='Longitude' onChange={(e) => setGpsLongitude(e.target.value)} value={gpsLongitude}/>
                    </div>
                    { notCalibrate &&
                    <>
                    <div className='form-tambah'>
                        <input className='form-input-3' type='number' placeholder='Nomor Telepon Outlet' onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))} value={phone_number}/>
                    </div>
                    <div className='form-tambah'>
                        <input className='form-input-3' placeholder='Nama Pemilik Outlet' onChange={(e) => setNamaPemilik(e.target.value)} value={nama_pemilik}/>
                    </div>
                    </>}
                    <div className='form-tambah'>
                        <button className='outlet-checkout-button' onClick={() => validateForm()}>SIMPAN</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalVisible} onClosed={() => setModalVisible(false)} centered toggle={() => setModalVisible(false)} className='modal-gambar'>
                    <button className='btn-red' onClick={() => fileInputRef.current.click()}>FOTO DARI GALERI</button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="imageURI"
                        onChange={(event) => {
                            console.log(event.target.files[0])
                            setImageURI(URL.createObjectURL(event.target.files[0]))
                            setImageFile(event.target.files[0])
                            setImageName(format(new Date(), "yyyyMMdd")+event.target.files[0].name)
                            setModalVisible(false)
                        }}
                        style={{display:'none'}}
                    />
                    <button className='btn-red' onClick={() => setModalCamera(true)}>FOTO DARI KAMERA</button>
                    <Modal isOpen={modalCamera} onClosed={() => setModalCamera(false)} centered toggle={() => setModalCamera(false)} fullscreen className='modal-gambar'>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            minScreenshotWidth={640}
                            minScreenshotHeight={'100%'}
                            screenshotQuality={1}
                            label="wow"
                            style={{
                            position: "absolute",
                            zindex: 888,
                            left:0,
                            right:0,
                            height: "90vh",
                            width: "100%",
                            objectFit: "cover",
                            }}
                        />
                        <div className='group-camera-buttons'>
                            <div className='camera-button' onClick={() => setModalCamera(false)}>
                                <p style={{color:"black"}}>BATAL</p>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => takeAPic()} src={capturePic} width={70} height={70}/>
                            </div>
                            <div className='camera-button'>
                                <img onClick={() => switchCamera(!cameraPosition)} src={flipPic} width={110} height={60}/>
                            </div>
                        </div>
                    </Modal>
            </Modal>
        </div>
    )
}

export default TambahOutlet