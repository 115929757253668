import '../../App.css';
import React,{useRef, useState,useCallback, useEffect} from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { format } from "date-fns";
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import toast from 'react-hot-toast'
import { getUser } from '../../Helpers/auth-helper.js';
import { getData, postData, postFile } from '../../Helpers/api-helper.js';
import Loader from '../../Components/Loader.js'
import { ExpandMore,ExpandLess } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import Select from 'react-select'

function RegistrasiOutlet(){

	const {state} = useLocation();
    const {data_outlet} = state;
    const [loading, setLoading] = useState(false);

    const [company, set_company] = useState(data_outlet?.perusahaan || "");
    const [kategori_md, set_kategori_md] = useState(data_outlet?.kategori_md || "");
    const [jenis_md, set_jenis_md] = useState(data_outlet?.jenis_md || "");
    const [data_utama, set_data_utama] = useState(data_outlet?.tipe_data_utama || "");
    const [outletName, setOutletName] = useState(data_outlet?.nama_toko || "");
    const [nama_pt, setNamaPT] = useState(data_outlet?.nama_pt || "");
    const [nomor_npwp, setNoNPWP] = useState(data_outlet?.nomor_npwp || "");
    const [alamat_npwp, setAlamatNPWP] = useState(data_outlet?.alamat_npwp || "");

    const [phone_number, setPhoneNumber] = useState(data_outlet?.nomor_telepon || '');
    const [nama_pemilik, setNamaPemilik] = useState(data_outlet?.nama_owner || '');
    const [email_pemilik, setEmailPemilik] = useState(data_outlet?.alamat_email || "");
    const [nik_pemilik,setNIK] = useState(data_outlet?.nomor_nik || "");

    const [nama_pb, setNamaPB] = useState(data_outlet?.nama_pb || "");
    const [phone_number_pb, setPhoneNumberPB] = useState(data_outlet?.nomor_telepon_pb || "");
    const [alamat_pb, setAlamatPB] = useState(data_outlet?.alamat_pengiriman_barang || "");

    const [nama_finance, setNamaFinance] = useState(data_outlet?.nama_finance || "");
    const [phone_number_pt, setPhoneNumberPT] = useState(data_outlet?.nomor_telepon_finance || "");
    const [alamat_pt, setAlamatPT] = useState(data_outlet?.alamat_pengiriman_tagihan || "");
    const [email_finance, setEmailFinance] = useState(data_outlet?.email_finance || "");

    const [nama_bank, setNamaBank] = useState(data_outlet?.nama_bank || "");
    const [nama_pemilik_rekening, setNamaPemilikRekening] = useState(data_outlet?.nama_pemilik_rekening || "");
    const [nomor_rekening, setNomorRekening] = useState(data_outlet?.nomor_rekening || "");
    const [cabang_kota, setCabangKota] = useState(data_outlet?.cabang_kota || "");

    const [jenis_pembayaran, setJenisPembayaran] = useState(data_outlet?.jenis_pembayaran || "");
    const [limit, setLimit] = useState(data_outlet?.limit_top || "");
    const [pajak, setPajak] = useState(data_outlet?.pajak || "");
    const [RSM, setRSM] = useState(data_outlet?.RSM || "");
    const [sales, setSales] = useState(data_outlet?.sales || "");
    const [AE, setAE] = useState(data_outlet?.AE || "");
    const [AR, setAR] = useState(data_outlet?.AR || "");
    const [latest_status, set_latest_status] = useState(data_outlet?.latest_status || "");

    const [col_company, set_col_company] = useState(true);
    const [col_owner, set_col_owner] = useState(true);
    const [col_pb, set_col_pb] = useState(true);
    const [col_t, set_col_t] = useState(true);
    const [col_bank, set_col_bank] = useState(true);
    const [col_jenis_p, set_col_jenis_p] = useState(true);
    const [col_lampiran, set_col_lampiran] = useState(true);

    const [modal_alasan, set_modal_alasan] = useState(data_outlet?.kode_customer && data_outlet?.latest_status === 'rejected' ? true : false);

    const [lampiran_npwp, set_lampiran_npwp] = useState(null);
    const [lampiran_ktp, set_lampiran_ktp] = useState(null);
    const [lampiran_sppkp, set_lampiran_sppkp] = useState(null);
    const [lampiran_nib, set_lampiran_nib] = useState(null);
    const [lampiran_tdp, set_lampiran_tdp] = useState(null);
    const [lampiran_akta, set_lampiran_akta] = useState(null);
    const [lampiran_buku_rekening, set_lampiran_buku_rekening] = useState(null);
    const [lampiran_po, set_lampiran_po] = useState(null);
    const [lampiran_pks, set_lampiran_pks] = useState(null);

    const [halaman_active,set_halaman_active] = useState(1);

    const [active_form,set_active_form] = useState(data_outlet?.form ? data_outlet?.form : 'BANGGA');

    const navigate = useNavigate()

    const [loading_npwp,setLoadingNPWP] = useState(false); 
    const [loading_ktp,setLoadingKTP] = useState(false); 
    const [loading_sppkp,setLoadingSPPKP] = useState(false); 
    const [loading_nib,setLoadingNIB] = useState(false); 
    const [loading_tdp,setLoadingTDP] = useState(false); 
    const [loading_akta,setLoadingAkta] = useState(false); 
    const [loading_bank,setLoadingBank] = useState(false); 
    const [loading_po,setLoadingPO] = useState(false); 
    const [loading_pks,setLoadingPKS] = useState(false); 

    const [selectedCompanyArray,setSelectedCompanyArray] = useState();
    const [selectedCompany,setSelectedCompany] = useState("");
    const companies = [
        {
            type:"BANGGA",
            value:"BANGGA",
            label:"BANGGA"
        },{
            type:"BANGGA",
            value:"INTECH",
            label:"INTECH"
        },,{
            type:"INTECH",
            value:"INTECH",
            label:"INTECH"
        },{
            type:"INTECH",
            value:"PKP",
            label:"PKP"
        },{
            type:"INTECH",
            value:"NON PKP",
            label:"NON PKP"
        },{
            type:"INTECH",
            value:"PROJECT",
            label:"PROJECT"
        }
    ]
    const kategori_md_array = [
        {
            value:"TRADISIONAL",
            label:"TRADISIONAL"
        },{
            value:"PROJECT",
            label:"PROJECT"
        }
    ]
    const jenis_md_array = [
        {
            value:"RETAIL",
            label:"RETAIL"
        },{
            value:"DISTRIBUSI",
            label:"DISTRIBUSI"
        }
    ]
    const data_utama_array = [
        {
            value:"PERUSAHAAN",
            label:"PERUSAHAAN"
        },{
            value:"PERORANGAN",
            label:"PERORANGAN"
        }
    ]
    const jenis_pembayaran_array = [
        {
            value:"CASH",
            label:"CASH"
        },{
            value:"COD",
            label:"COD"
        },{
            value:"TOP",
            label:"TOP"
        }
    ]
    const pajak_array = [
        {
            value:"BULANAN",
            label:"BULANAN"
        },{
            value:"HARIAN",
            label:"HARIAN"
        }
    ]
    const [selectedJenisMDArray,setSelectedJenisMDArray] = useState();
    const [selectedJenisMD,setSelectedJenisMD] = useState("");
    const [selectedKategoriMDArray,setSelectedKategoriMDArray] = useState();
    const [selectedKategoriMD,setSelectedKategoriMD] = useState("");
    const [selectedDataUtamaArray,setSelectedDataUtamaArray] = useState();
    const [selectedDataUtama,setSelectedDataUtama] = useState("");
    const [selectedPajakArray,setSelectedPajakArray] = useState();
    const [selectedPajak,setSelectedPajak] = useState("");
    const [selectedJenisPembayaranArray,setSelectedJenisPembayaranArray] = useState();
    const [selectedJenisPembayaran,setSelectedJenisPembayaran] = useState("");

    useEffect(() => {
        console.log(data_outlet?.form)
        // refreshView()
        if(data_outlet?.kode_customer){
            getLampiran(data_outlet?.kode_customer)

            setSelectedCompany(data_outlet?.perusahaan)
            var company_array = companies.filter(item => item.value == data_outlet?.perusahaan)
            setSelectedCompanyArray(company_array[0])

            setSelectedJenisMD(data_outlet?.jenis_md)
            var jenis_array = jenis_md_array.filter(item => item.value == data_outlet?.jenis_md)
            setSelectedJenisMDArray(jenis_array[0])

            setSelectedKategoriMD(data_outlet?.kategori_md)
            var kategori_array = kategori_md_array.filter(item => item.value == data_outlet?.kategori_md)
            setSelectedKategoriMDArray(kategori_array[0])

            setSelectedDataUtama(data_outlet?.tipe_data_utama)
            var data_array = data_utama_array.filter(item => item.value == data_outlet?.tipe_data_utama)
            setSelectedDataUtamaArray(data_array[0])

            setSelectedJenisPembayaran(data_outlet?.jenis_pembayaran)
            var pembayaran_array = jenis_pembayaran_array.filter(item => item.value == data_outlet?.jenis_pembayaran)
            setSelectedJenisPembayaranArray(pembayaran_array[0])

            setSelectedPajak(data_outlet?.pajak)
            var paj_array = pajak_array.filter(item => item.value == data_outlet?.pajak)
            setSelectedPajakArray(paj_array[0])
        }
    },[])

    const getLampiran=async(kode_customer)=>{
        setLoadingNPWP(true)
        setLoadingKTP(true)
        setLoadingSPPKP(true)
        setLoadingNIB(true)
        setLoadingTDP(true)
        setLoadingAkta(true)
        setLoadingBank(true)
        setLoadingPO(true)
        setLoadingPKS(true)
        const response = await getData(process.env.REACT_APP_BACKEND_URL+"/get_lampiran_by_kode_customer?kode_customer="+kode_customer,null)

        if(response){
            if(response?.data){
                response.data.map((data)=>{
                    turnIntoFile(data)
                })
            }
        }
    }

    const turnIntoFile =async (data)=>{
        let response_file = await fetch(data.file_src);
        let file = await response_file.blob();
        var fileName = data.file_name;
        let metadata = {
            type: data.file_extention
        };
        let new_file = new File([file], fileName, metadata);
        initSetFile(data.tipe_dokumen,new_file)
        initLoadingFile(data.tipe_dokumen,false)
    }

    const refreshView = () => {
        setSelectedCompany("");
        setSelectedCompanyArray([])
        setSelectedKategoriMD("");
        setSelectedKategoriMDArray([])
        setSelectedJenisMD("");
        setSelectedJenisMDArray([])
        setSelectedDataUtama("");
        setSelectedDataUtamaArray([])
        setOutletName("");
        setNamaPT("");
        setNoNPWP("");
        setAlamatNPWP("");
        setPhoneNumber('');
        setNamaPemilik('');
        setEmailPemilik("");
        setNIK("");
        setNamaPB();
        setPhoneNumberPB();
        setAlamatPB();
        setNamaFinance("");
        setPhoneNumberPT("");
        setAlamatPT("");
        setEmailFinance("");
        setNamaBank("");
        setNamaPemilikRekening("");
        setNomorRekening("");
        setCabangKota("");
    
        setSelectedJenisPembayaran("");
        setSelectedJenisPembayaranArray([])
        setLimit("");
        setSelectedPajak("");
        setSelectedPajakArray([])
        setRSM();
        setSales();
        setAE();
        setAR();
    }

    const validateForm=async()=>{
        if(lampiran_npwp == null){
            toast.error('Isi Lapiran NPWP ( Nomor Pokok Wajib Pajak ), Perusahaan atau Perorangan')
        }else if(lampiran_ktp == null){
            toast.error('Isi Lapiran KTP ( Kartu Tanda Penduduk ), Penanggung Jawab Perusahaan')
        }else if(lampiran_sppkp == null){
            toast.error('Isi Lapiran SPPKP ( Surat Ketetapan Pengukuhan Kena Pajak )')
        }else if(lampiran_nib == null && active_form === 'BANGGA'){
            toast.error('Isi Lapiran NIB ( Nomor Induk Berusaha )')
        }else if(lampiran_tdp == null && active_form === 'BANGGA'){
            toast.error('Isi Lapiran TDP & SIUP')
        }else if(lampiran_akta == null && active_form === 'BANGGA'){
            toast.error('Isi Lapiran Akta Perusahaan')
        }else if(lampiran_buku_rekening == null && active_form === 'BANGGA'){
            toast.error('Isi Lapiran Tampilan depan Buku Rekening Bank Perusahaan')
        }else if(lampiran_po == null && active_form === 'BANGGA'){
            toast.error('Isi Lapiran PO Pembelian')
        }else if(lampiran_pks == null){
            toast.error('Isi Lapiran PKS ( Perjanjian Kerja Sama )')
        }else{
            handleSubmitForm()
        }
    }

    const handleSubmitForm=async()=>{
        
        var data_user = getUser()
        var user_id = data_user?.user_id ? data_user?.user_id : data_user?.id
        var kode_customer = data_outlet?.kode_customer ? data_outlet?.kode_customer : null
        let dataOptions = {kode_customer:kode_customer,form:active_form,perusahaan: selectedCompany,kategori_md:selectedKategoriMD,jenis_md:selectedJenisMD,tipe_data_utama:selectedDataUtama,nama_pt:nama_pt,nama_toko: active_form === 'INTECH' ? nama_pt : outletName,nomor_npwp:nomor_npwp,alamat_npwp:alamat_npwp,
            email_pemilik:email_pemilik,nama_owner:nama_pemilik,nomor_telepon:phone_number,nik_pemilik:nik_pemilik,
            alamat_pengiriman_barang:alamat_pb,nomor_telepon_pb:phone_number_pb,nama_pb:nama_pb,alamat_pengiriman_tagihan:alamat_pt,nama_finance:nama_finance,nomor_telepon_pt:phone_number_pt,email_finance:email_finance,
            nama_bank:nama_bank,nama_pemilik_rekening:nama_pemilik_rekening,nomor_rekening:nomor_rekening,cabang_kota:cabang_kota,
            jenis_pembayaran:selectedJenisPembayaran,limit_top:limit,pajak:selectedPajak,RSM:RSM,sales:sales,AE:AE,AR:AR,created_by:user_id
        };
      
        // setTimeOut(global.timeOut/1000)
        setLoading(true)
        const response = await postData(process.env.REACT_APP_BACKEND_URL+'/register_outlet', dataOptions)

        if(response){
            if (response?.data?.kode_customer) {
                toast('Uploading lampiran')
                StartUploadLampiran(response?.data?.kode_customer)
                refreshView()
            } else {
                var resultMessage = response?.data?.message?.toString();
                console.log('resultMessage', resultMessage);
                toast.error(resultMessage)
            }
        }
    }
    
    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const StartUploadLampiran=async(kode_customer)=>{
        if(active_form === 'BANGGA'){
            await UploadLampiran(lampiran_npwp,'npwp',kode_customer); 
            await UploadLampiran(lampiran_ktp,'ktp',kode_customer); 
            await UploadLampiran(lampiran_sppkp,'sppkp',kode_customer); 
            await UploadLampiran(lampiran_nib,'nib',kode_customer); 
            await UploadLampiran(lampiran_tdp,'tdp',kode_customer); 
            await UploadLampiran(lampiran_akta,'akta',kode_customer); 
            await UploadLampiran(lampiran_buku_rekening,'bank',kode_customer); 
            await UploadLampiran(lampiran_po,'po',kode_customer); 
            await UploadLampiran(lampiran_pks,'pks',kode_customer); 
        }else{
            await UploadLampiran(lampiran_npwp,'npwp',kode_customer); 
            await UploadLampiran(lampiran_ktp,'ktp',kode_customer); 
            await UploadLampiran(lampiran_sppkp,'sppkp',kode_customer); 
            await UploadLampiran(lampiran_pks,'pks',kode_customer); 
        }
        setLoading(false)
        toast.success('Upload lampiran selesai')
        navigate(-1)
    }

    const UploadLampiran=async(lampiran,tipe_dokumen,kode_customer)=>{
      const response = await postFile(lampiran,lampiran.name,lampiran.type)
  
      if (response) {
        setLoading(false)
        if (response?.data?.success == 1) {
            var file_src = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_API_FOLDER + '/' + process.env.REACT_APP_BINARY_FOLDER + '/' + 
            lampiran.name.substring(0,4) + '/' + lampiran.name.substring(4,6) + '/' 
            + lampiran.name.substring(6,8) + '/' + lampiran.name;
            console.log(file_src)
            var tipe_api = data_outlet?.kode_customer ? 'update' : 'insert'
            var dataOptions = {file_src,tipe_dokumen,kode_customer,file_extention:lampiran.type,file_name:lampiran.name,tipe_api:tipe_api}
            initLoadingFile(tipe_dokumen,true)
            const response_file = await postData(process.env.REACT_APP_BACKEND_URL+'/insert_customer_document', dataOptions)
            if(tipe_dokumen === 'npwp'){
                setLoadingNPWP(false)
                set_lampiran_npwp(null)
            }else if(tipe_dokumen == 'ktp'){
                setLoadingKTP(false)
                set_lampiran_ktp(null)
            }else if(tipe_dokumen == 'sppkp'){
                setLoadingSPPKP(false) 
                set_lampiran_sppkp(null)
            }else if(tipe_dokumen == 'nib'){
                setLoadingNIB(false)  
                set_lampiran_nib(null)
            }else if(tipe_dokumen == 'tdp'){
                setLoadingTDP(false) 
                set_lampiran_tdp(null)
            }else if(tipe_dokumen == 'akta'){
                setLoadingAkta(false)
                set_lampiran_akta(null)
            }else if(tipe_dokumen == 'bank'){
                setLoadingBank(false)
                set_lampiran_buku_rekening(null)
            }else if(tipe_dokumen == 'po'){
                setLoadingPO(false)
                set_lampiran_po(null)
            }else if(tipe_dokumen == 'pks'){
                setLoadingPKS(false)
                set_lampiran_pks(null)
            }
        } else {
          var resultMessage = response?.data?.message?.toString();
          console.log('resultMessage', response);
          if (resultMessage !== "File sudah ada di server") {
            // toast.error(resultMessage);
            return false
          }
        }
        }
    }

    const hiddenFileInput_npwp = useRef(null); 
    const hiddenFileInput_ktp = useRef(null); 
    const hiddenFileInput_sppkp = useRef(null); 
    const hiddenFileInput_nib = useRef(null); 
    const hiddenFileInput_tdp = useRef(null); 
    const hiddenFileInput_akta = useRef(null); 
    const hiddenFileInput_bank = useRef(null); 
    const hiddenFileInput_po = useRef(null); 
    const hiddenFileInput_pks = useRef(null); 

    const handleClick = (name) => {
        if(name === 'npwp'){
            hiddenFileInput_npwp.current.click();   
        }else if(name == 'ktp'){
            hiddenFileInput_ktp.current.click();   
        }else if(name == 'sppkp'){
            hiddenFileInput_sppkp.current.click();   
        }else if(name == 'nib'){
            hiddenFileInput_nib.current.click();   
        }else if(name == 'tdp'){
            hiddenFileInput_tdp.current.click();   
        }else if(name == 'akta'){
            hiddenFileInput_akta.current.click();   
        }else if(name == 'bank'){
            hiddenFileInput_bank.current.click();   
        }else if(name == 'po'){
            hiddenFileInput_po.current.click();   
        }else if(name == 'pks'){
            hiddenFileInput_pks.current.click();   
        }
    };

    const initLoadingFile=(tipe_dokumen,value)=>{
        if(tipe_dokumen === 'npwp'){
            setLoadingNPWP(value)
        }else if(tipe_dokumen == 'ktp'){
            setLoadingKTP(value)
        }else if(tipe_dokumen == 'sppkp'){
            setLoadingSPPKP(value) 
        }else if(tipe_dokumen == 'nib'){
            setLoadingNIB(value)  
        }else if(tipe_dokumen == 'tdp'){
            setLoadingTDP(value) 
        }else if(tipe_dokumen == 'akta'){
            setLoadingAkta(value)
        }else if(tipe_dokumen == 'bank'){
            setLoadingBank(value)
        }else if(tipe_dokumen == 'po'){
            setLoadingPO(value)
        }else if(tipe_dokumen == 'pks'){
            setLoadingPKS(value)
        }
    }

    const initSetFile=(name,new_file)=>{
        if(name === 'npwp'){
            set_lampiran_npwp(new_file) 
        }else if(name == 'ktp'){
            set_lampiran_ktp(new_file)  
        }else if(name == 'sppkp'){
            set_lampiran_sppkp(new_file)   
        }else if(name == 'nib'){
            set_lampiran_nib(new_file)  
        }else if(name == 'tdp'){
            set_lampiran_tdp(new_file)   
        }else if(name == 'akta'){
            set_lampiran_akta(new_file)   
        }else if(name == 'bank'){
            set_lampiran_buku_rekening(new_file)    
        }else if(name == 'po'){
            set_lampiran_po(new_file)  
        }else if(name == 'pks'){
            set_lampiran_pks(new_file)  
        }
    }

    const handleChange = async (name,event) => {
        const fileUploaded = event.target.files[0];
        if(event.target.files.length > 0){
            initLoadingFile(name,true)
            let response_img = await fetch(URL.createObjectURL(fileUploaded));
            let data = await response_img.blob();
            var fileName = format(new Date(), "yyyyMMdd")+new Date().getTime()+"-"+name+"."+getExtension(fileUploaded.name);
            let metadata = {
              type: fileUploaded.type
            };
            if(fileUploaded.type === 'application/x-msdownload'){
                toast.error('Tidak dapat upload file tipe ini')
                initLoadingFile(name,false)
            }else if(fileUploaded.size >= 10195488){
                toast.error('Ukuran file terlalu besar (Max 10Mb)')
                initLoadingFile(name,false)
            }else{
                if(fileUploaded.type === 'image/jpeg' || fileUploaded.type === 'image/jpg' || fileUploaded.type === 'image/png'){
                    const options = {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                    }
                    const compressedFile = await imageCompression(fileUploaded, options);
                    var new_file = new File(
                        [compressedFile],
                        fileName,
                    { type :'image/jpeg'})
                    console.log("compress file")
                }else{
                    var new_file = new File([data], fileName, metadata);
                    console.log("file created")
                }
                console.log(new_file,"new_file")
                initSetFile(name,new_file)
                initLoadingFile(name,false)
            }
        }
    };

    const nextPage=()=>{
        if(halaman_active === 1){
            if(selectedCompany === ""){
                toast.error("Pilih Company!");
            }else if(selectedKategoriMD === "" && active_form === 'BANGGA'){
                toast.error("Pilih Kategori MD!");
            }else if(selectedJenisMD === "" && active_form === 'BANGGA'){
                toast.error("Pilih Jenis MD");
            }else if(selectedDataUtama === "" && active_form === 'BANGGA'){
                toast.error("Pilih Data utama!");
            }else if(nama_pt === ""){
                toast.error("Isi Nama PT!");
            }else if(nomor_npwp === ""){
                toast.error("Isi NPWP!");
            }else if(alamat_npwp === ""){
                toast.error("Isi Alamat NPWP!");
            }else if(outletName === "" && active_form === 'BANGGA'){
                toast.error("Isi Nama Outlet!");
            }else if(nama_pemilik === ""){
                toast.error("Isi Nama Pemilik toko!");
            }else if(phone_number === ""){
                toast.error("Isi Nomor Pemilik toko!");
            }else if(email_pemilik === ""){
                toast.error("Isi Email Pemilik toko!");
            }else{
                set_halaman_active(halaman_active+1)
            }
        }else if(halaman_active === 2){
            if(email_finance === ""){
                toast.error("Isi Email Finance!");
            }else if(selectedJenisPembayaran === ""){
                toast.error("Pilih Jenis Pembayaran");
            }else if(selectedJenisPembayaran === "TOP" && (limit <= 0 || limit === "")){
                toast.error("Isi Limit!");
            }else if(selectedPajak === ""){
                toast.error("Pilih Pajak!");
            }else{
                set_halaman_active(halaman_active+1)
            }
        }
    }

    const prevPage=()=>{
        set_halaman_active(halaman_active-1)
    }

    const onSelectChangeCompany=(selectedItems)=>{
        setSelectedCompany(selectedItems.value.value);
        setSelectedCompanyArray(selectedItems.value)
    }

    const onSelectChangeKategoriMD=(selectedItems)=>{
        setSelectedKategoriMD(selectedItems.value.value);
        setSelectedKategoriMDArray(selectedItems.value)
    }

    const onSelectChangeJenisMD=(selectedItems)=>{
        setSelectedJenisMD(selectedItems.value.value);
        setSelectedJenisMDArray(selectedItems.value)
    }

    const onSelectChangeJenisPembayaran=(selectedItems)=>{
        setSelectedJenisPembayaran(selectedItems.value.value);
        setSelectedJenisPembayaranArray(selectedItems.value)
    }

    const onSelectChangeDataUtama=(selectedItems)=>{
        setSelectedDataUtama(selectedItems.value.value);
        setSelectedDataUtamaArray(selectedItems.value)
    }

    const onSelectChangePajak=(selectedItems)=>{
        setSelectedPajak(selectedItems.value.value);
        setSelectedPajakArray(selectedItems.value)
    }

    return(
        <div className='body-register'>
            <Loader loading={loading} timeOut={60}/>
            <div className='wrap-scroll' style={{height:'90vh'}}>
                <div className='scroll-content'>
                    { halaman_active === 1 && <>
                    {data_outlet?.alasan && latest_status === 'rejected' &&
                    <div className='form-tambah-2'>
                        <button className={"btn-white-1"} onClick={() => set_modal_alasan(true)}>Lihat Alasan Reject</button>
                    </div>}
                    <br></br>
                    <h4 style={{fontWeight:700}}>Form </h4>
                    <div className='form-tambah-2 d-flex'>
                        <button className={ active_form === 'BANGGA' ? "btn-red-1" : "btn-white-1"} onClick={() => set_active_form('BANGGA')}>BANGGA</button>
                        <button className={ active_form === 'INTECH' ? "btn-red-1" : "btn-white-1"} onClick={() => set_active_form('INTECH')}>INTECH</button>
                    </div>
                    <div className='form-tambah-2'>
                        <Select
                            className="form-select-2"
                            classNamePrefix="Pilih Company"
                            placeholder={'Pilih Company'}
                            isSearchable={false}
                            name="company"
                            value={selectedCompanyArray}
                            options={companies.filter(item => item.type == active_form)}
                            onChange={(value,e) => onSelectChangeCompany({value})}
                        />
                    </div>
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                            <Select
                                className="form-select-2"
                                classNamePrefix="Pilih Kategori MD"
                                placeholder={'Pilih Kategori MD'}
                                isSearchable={false}
                                name="kategori_md"
                                value={selectedKategoriMDArray}
                                options={kategori_md_array}
                                onChange={(value,e) => onSelectChangeKategoriMD({value})}
                            />
                    </div>}
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                            <Select
                                className="form-select-2"
                                classNamePrefix="Pilih Jenis MD"
                                placeholder={'Pilih Jenis MD'}
                                isSearchable={false}
                                name="jenis_md"
                                value={selectedJenisMDArray}
                                options={jenis_md_array}
                                onChange={(value,e) => onSelectChangeJenisMD({value})}
                            />
                    </div>}
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                            <Select
                                className="form-select-2"
                                classNamePrefix="Pilih Data Utama"
                                placeholder={'Pilih Data Utama'}
                                isSearchable={false}
                                name="data_utama"
                                value={selectedDataUtamaArray}
                                options={data_utama_array}
                                onChange={(value,e) => onSelectChangeDataUtama({value})}
                            />
                    </div>}
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_company(!col_company)} style={{fontWeight:700}}>Data Perusahaan {
                            col_company == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_company}>
                            <input className='form-input-3' placeholder='Nama PT/CV' onChange={(e) => setNamaPT(e.target.value)} value={nama_pt}/>
                            <input className='form-input-3' placeholder='Nomor NPWP' onChange={(e) => setNoNPWP(e.target.value)} value={nomor_npwp}/>
                            <textarea className='form-textarea-1' placeholder='Alamat NPWP' onChange={(e) => setAlamatNPWP(e.target.value)} value={alamat_npwp}/>
                            <input className='form-input-3' placeholder='Nama Outlet' onChange={(e) => setOutletName(e.target.value)} value={outletName}/>
                        </Collapse>
                    </div>}
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_owner(!col_owner)} style={{fontWeight:700}}>Data Pemilik {
                            col_owner == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_owner}>
                            <input className='form-input-3' placeholder='Nama Penanggung Jawab ( Direktur )' onChange={(e) => setNamaPemilik(e.target.value)} value={nama_pemilik}/>
                            <input className='form-input-3' type='number' placeholder='Nomor Telepon Outlet' onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))} value={phone_number}/>
                            <input className='form-input-3' type='number' placeholder='NIK' onChange={(e) => setNIK(e.target.value.replace(/[^0-9]/g, ''))} value={nik_pemilik}/>
                            <input className='form-input-3' placeholder='Email Pemilik Outlet' onChange={(e) => setEmailPemilik(e.target.value)} value={email_pemilik}/>
                        </Collapse>
                    </div>}
                    {active_form === 'INTECH' && <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_owner(!col_owner)} style={{fontWeight:700}}>Data Perusahaan {
                            col_owner == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_owner}>
                            <input className='form-input-3' placeholder='Nama PT/CV' onChange={(e) => setNamaPT(e.target.value)} value={nama_pt}/>
                            <input className='form-input-3' placeholder='Nama Penanggung Jawab ( Direktur )' onChange={(e) => setNamaPemilik(e.target.value)} value={nama_pemilik}/>
                            <input className='form-input-3' placeholder='Nomor NPWP' onChange={(e) => setNoNPWP(e.target.value)} value={nomor_npwp}/>
                            <input className='form-input-3' type='number' placeholder='NIK' onChange={(e) => setNIK(e.target.value.replace(/[^0-9]/g, ''))} value={nik_pemilik}/>
                            <textarea className='form-textarea-1' placeholder='Alamat NPWP' onChange={(e) => setAlamatNPWP(e.target.value)} value={alamat_npwp}/>
                            <input className='form-input-3' type='number' placeholder='Nomor Telepon Outlet' onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ''))} value={phone_number}/>
                            <input className='form-input-3' placeholder='Email Pemilik Outlet' onChange={(e) => setEmailPemilik(e.target.value)} value={email_pemilik}/>
                        </Collapse>
                    </div>}
                    <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_pb(!col_pb)} style={{fontWeight:700}}>Data Pengiriman Barang {
                            col_pb == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_pb}>
                            <textarea className='form-textarea-1' placeholder='Alamat Pengiriman Barang' onChange={(e) => setAlamatPB(e.target.value)} value={alamat_pb}/>
                            <input className='form-input-3' type='number' placeholder='Nomor Telepon' onChange={(e) => setPhoneNumberPB(e.target.value.replace(/[^0-9]/g, ''))} value={phone_number_pb}/>
                            <input className='form-input-3' placeholder='Nama Penerima Barang' onChange={(e) => setNamaPB(e.target.value)} value={nama_pb}/>
                        </Collapse>
                    </div></>}
                    { halaman_active === 2 &&  <> 
                    <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_t(!col_t)} style={{fontWeight:700}}>Data Tagihan {
                            col_t == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_t}>
                            <textarea className='form-textarea-1' placeholder='Alamat Pengiriman Tagihan' onChange={(e) => setAlamatPT(e.target.value)} value={alamat_pt}/>
                            <input className='form-input-3' type='number' placeholder='Nomor Telepon' onChange={(e) => setPhoneNumberPT(e.target.value.replace(/[^0-9]/g, ''))} value={phone_number_pt}/>
                            <input className='form-input-3' placeholder='Nama Finance/Accounting' onChange={(e) => setNamaFinance(e.target.value)} value={nama_finance}/>
                            <input className='form-input-3' placeholder='Email Finance' onChange={(e) => setEmailFinance(e.target.value)} value={email_finance}/>
                        </Collapse>
                    </div>
                    {active_form === 'BANGGA' && <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_bank(!col_bank)} style={{fontWeight:700}}>Data Bank {
                            col_bank == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_bank}>
                        <input className='form-input-3' placeholder='Nama Bank' onChange={(e) => setNamaBank(e.target.value)} value={nama_bank}/>
                        <input className='form-input-3' placeholder='Nama Pemilik Rekening' onChange={(e) => setNamaPemilikRekening(e.target.value)} value={nama_pemilik_rekening}/>
                        <input className='form-input-3' type='number' placeholder='Nomor Rekening' onChange={(e) => setNomorRekening(e.target.value)} value={nomor_rekening}/>
                        <input className='form-input-3' placeholder='Cabang Kota' onChange={(e) => setCabangKota(e.target.value)} value={cabang_kota}/>
                        </Collapse>
                    </div>}
                    <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_jenis_p(!col_jenis_p)} style={{fontWeight:700}}>Jenis Pembayaran {
                            col_jenis_p == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_jenis_p}>
                                <Select
                                    className="form-select-2"
                                    classNamePrefix="Pilih Jenis Pembayaran"
                                    placeholder={'Pilih Jenis Pembayaran'}
                                    isSearchable={false}
                                    name="jenis_pembayaran"
                                    value={selectedJenisPembayaranArray}
                                    options={jenis_pembayaran_array}
                                    onChange={(value,e) => onSelectChangeJenisPembayaran({value})}
                                />
                            {selectedJenisPembayaran === 'TOP' && <div className='form-tambah'>
                                <input className='form-input-3' type='number' placeholder='Limit' onChange={(e) => setLimit(e.target.value)} value={limit}/>
                            </div>}
                            <Select
                                className="form-select-2"
                                classNamePrefix="Pilih Pajak"
                                placeholder={'Pilih Pajak'}
                                isSearchable={false}
                                name="pajak"
                                value={selectedPajakArray}
                                options={pajak_array}
                                onChange={(value,e) => onSelectChangePajak({value})}
                            />
                            <input className='form-input-3' placeholder='RSM' onChange={(e) => setRSM(e.target.value)} value={RSM}/>
                            <input className='form-input-3' placeholder='SALES' onChange={(e) => setSales(e.target.value)} value={sales}/>
                            <input className='form-input-3' placeholder='AE' onChange={(e) => setAE(e.target.value)} value={AE}/>
                            <input className='form-input-3' placeholder='AR' onChange={(e) => setAR(e.target.value)} value={AR}/>
                        </Collapse>
                    </div></>}
                    { halaman_active === 3 && 
                    <div className='form-tambah-2'>
                        <h4 onClick={() => set_col_lampiran(!col_lampiran)} style={{fontWeight:700}}>Lampiran Dokumen {
                            col_lampiran == true ? <ExpandLess style={{color:"black"}}/> : <ExpandMore style={{color:"black"}}/>
                        }</h4>
                        <Collapse isOpen={col_lampiran}>
                            <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>NPWP</label>
                                <label>{lampiran_npwp?.name}</label>
                                { loading_npwp ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('npwp')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('npwp',e)}
                                    ref={hiddenFileInput_npwp}
                                    style={{display:'none'}}
                                />
                            </div>
                            <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>KTP</label>
                                <label>{lampiran_ktp?.name}</label>
                                { loading_ktp ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('ktp')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('ktp',e)}
                                    ref={hiddenFileInput_ktp}
                                    style={{display:'none'}}
                                />
                            </div>
                            <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>SPPKP</label>
                                <label>{lampiran_sppkp?.name}</label>
                                { loading_sppkp ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('sppkp')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('sppkp',e)}
                                    ref={hiddenFileInput_sppkp}
                                    style={{display:'none'}}
                                />
                            </div>
                            {active_form === 'BANGGA' && <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>NIB</label>
                                <label>{lampiran_nib?.name}</label>
                                { loading_nib ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('nib')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('nib',e)}
                                    ref={hiddenFileInput_nib}
                                    style={{display:'none'}}
                                />
                            </div>}
                            {active_form === 'BANGGA' && <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>TDP & SIUP</label>
                                <label>{lampiran_tdp?.name}</label>
                                { loading_tdp ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('tdp')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('tdp',e)}
                                    ref={hiddenFileInput_tdp}
                                    style={{display:'none'}}
                                />
                            </div>}
                            {active_form === 'BANGGA' && <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>Akta Perusahaan</label>
                                <label>{lampiran_akta?.name}</label>
                                { loading_akta ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('akta')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('akta',e)}
                                    ref={hiddenFileInput_akta}
                                    style={{display:'none'}}
                                />
                            </div>}
                            {active_form === 'BANGGA' && <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>Tampilan depan buku rekening bank</label>
                                <label>{lampiran_buku_rekening?.name}</label>
                                { loading_bank ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('bank')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('bank',e)}
                                    ref={hiddenFileInput_bank}
                                    style={{display:'none'}}
                                />
                            </div>}
                            {active_form === 'BANGGA' && <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>PO Pembelian</label>
                                <label>{lampiran_po?.name}</label>
                                { loading_po ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('po')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('po',e)}
                                    ref={hiddenFileInput_po}
                                    style={{display:'none'}}
                                />
                            </div>}
                            <div className='item-lampiran'>
                                <label style={{fontWeight:700}}>PKS (Perjanjian Kerja Sama)</label>
                                <label>{lampiran_pks?.name}</label>
                                { loading_pks ? 
                                <Spinner color="danger"/>
                                : <button 
                                    className="button-upload"
                                    onClick={() => handleClick('pks')}
                                >
                                    Pilih File
                                </button>}
                                <input 
                                    type="file"
                                    onChange={(e) => handleChange('pks',e)}
                                    ref={hiddenFileInput_pks}
                                    style={{display:'none'}}
                                />
                            </div>
                        </Collapse>
                    </div>}
                    <div className="footer-register">
                        {   halaman_active >= 2 &&
                            <button className="btn-red-1" onClick={() => prevPage()}>KEMBALI</button>}
                        &nbsp;
                        { halaman_active != 3 && <button className="btn-red-1" onClick={() => nextPage()}>SELANJUTNYA</button>}
                        { halaman_active == 3 && (latest_status === 'rejected' || latest_status === '') && <button className="btn-red-1" onClick={() => validateForm()}>SIMPAN</button>}
                    </div>
                </div>
            </div>
            <Modal isOpen={modal_alasan} onClosed={() => set_modal_alasan(false)} onToggle={() => set_modal_alasan(false)}>
                <ModalHeader>
                    Alasan Reject
                </ModalHeader>
                <ModalBody>
                    {data_outlet?.alasan}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => set_modal_alasan(false)}>
                        Tutup
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default RegistrasiOutlet